<template>
    <v-col :class="defineTamanho">
        <slot></slot>
    </v-col>
</template>

<script>
export default {
    name: 'grid',
    components: {
    },
    props: ['tamanho'],
    data: () => ({}),
    computed: {
        defineTamanho: function() {
            if(this.tamanho >= 12 )
            {
                return "col-md-12";
            }
            if(this.tamanho <= 2 )
            {
                return "col-md-2";
            }
            if((this.tamanho % 2) === 0)
            {
                return "col-md-"+this.tamanho
            }
            else 
            {
                return "col-md-"+this.tamanho + 1
            }    
        }
    },
    methods: {}
}
</script>

<style>

</style>