<template>
  <div class="avaliacao-component">
    <v-card flat class="mt-0" elevation="0">
      <v-card-title>Informações</v-card-title>
      <v-card-text>
        <v-row>
          <Grid :tamanho="12">
            <v-list dense class="py-0">
              <v-list-item v-for="item in listaTiposAvaliacoes" :key="item.name" class="px-0">
                <v-icon max-width="35" contain class="me-3" large>
                  {{ item.icon }}
                </v-icon>
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-2">
                    <v-list-item-title class="text-sm">
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="mb-0">
                      {{ item.descricao }}
                    </v-list-item-subtitle>
                  </div>
                  <v-spacer></v-spacer>
                  <v-switch v-model="avaliacao[`${item.tipo}`]" @change="selectTabsUpdate()" true-value="A"
                    false-value="I"></v-switch>
                </div>
              </v-list-item>
            </v-list>
          </Grid>
          <v-col cols="12" md="6">
            <v-text-field label="Título da avaliação" placeholder="Ex.: Avaliar seu gestor" hide-details="auto" required
              outlined dense v-model="avaliacao.titulo" data-vv-as="titulo" persistent-hint :hint="errors.first('titulo')"
              :error="errors.collect('titulo').length ? true : false" v-validate="'required'" data-vv-validate-on="change"
              id="titulo" name="titulo" ref="titulo"></v-text-field>
          </v-col>
          <Grid :tamanho="listaGestores && listaGestores.length > 0 ? 6 : 4">
            <v-autocomplete label="Responsável" :items="listaGestores" item-value="id" item-text="nome" dense outlined
              hide-details="auto" v-model="avaliacao.id_responsavel" required no-data-text="Nenhum dado disponível"
              data-vv-as="nome" persistent-hint :hint="errors.first('nome')"
              :error="errors.collect('nome').length ? true : false" v-validate="'required'" data-vv-validate-on="change"
              id="nome" name="nome" ref="nome"></v-autocomplete>
          </Grid>
          <Grid :tamanho="6">
            <v-autocomplete label="Periodicidades" :items="listaPeriodicidades" item-value="name" item-text="name" dense
              outlined hide-details="auto" v-model="avaliacao.periodicidade" required
              no-data-text="Nenhum dado disponível" :rules="[validators.required]" data-vv-as="periodicidade"
              persistent-hint :hint="errors.first('periodicidade')"
              :error="errors.collect('periodicidade').length ? true : false" v-validate="'required'"
              data-vv-validate-on="change" id="periodicidade" name="periodicidade" ref="periodicidade"></v-autocomplete>
          </Grid>
          <Grid :tamanho="6">
            <v-menu ref="menuref" v-model="isStartDateMenuOpen" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto" max-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dateInitialFormatted" persistent-hint label="Data 1° avaliação"
                  append-icon="mdi-calendar-today" hide-details readonly dense outlined v-bind="attrs" @blur="
                    avaliacao.data_hora_inicio = parseDate(dateInitialFormatted)
                    " v-on="on" data-vv-as="data de início" :hint="errors.first('dataInicio')"
                  :error="errors.collect('dataInicio').length ? true : false" v-validate="'required'"
                  data-vv-validate-on="change" id="dataInicio" name="dataInicio" ref="dataInicio"></v-text-field>
              </template>

              <v-date-picker v-model="avaliacao.data_hora_inicio" no-title color="primary"
                @input="isStartDateMenuOpen = false" locale="pt" @change="validDateCurrent()"
                :min="validDateCurrent()"></v-date-picker>
            </v-menu>
          </Grid>
          <Grid :tamanho="12">
            <v-row>
              <v-col cols="12" md="3" sm="12">
                <v-autocomplete label="Está avaliação é para" :items="listaAvaliacaoPara" item-value="sigla"
                  item-text="name" dense outlined hide-details="auto" v-model="avaliacao.tipo_cliente"
                  no-data-text="Nenhum dado disponível" data-vv-as="tipo de cliente" persistent-hint
                  :hint="errors.first('tipo_cliente')" :error="errors.collect('tipo_cliente').length ? true : false"
                  data-vv-validate-on="change" id="tipo_cliente" v-validate="'required'" name="tipo_cliente"
                  ref="tipo_cliente" @change="formataCampos()"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="9" sm="12">
                <v-row>
                  <v-col cols="12" md="6" sm="12">
                    <v-autocomplete label="Filiais" :items="listaFiliais" item-value="id" item-text="nome" dense outlined
                      hide-details="auto" v-model="avaliacao.id_filial" required no-data-text="Nenhum dado disponível"
                      :loading="loadingFiliais" :disabled="avaliacao.tipo_cliente == 'propria' ? false : true
                        " data-vv-as="filial" persistent-hint :hint="errors.first('id_filial')"
                      :error="errors.collect('id_filial').length ? true : false" v-validate="avaliacao.tipo_cliente == 'propria' ? 'required' : ''
                        " data-vv-validate-on="change" id="id_filial" name="id_filial"
                      ref="id_filial"></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-autocomplete ref="id_cliente" label="Cliente" :items="listaClientes" item-value="id"
                      item-text="nome" dense outlined hide-details="auto" v-model="avaliacao.id_cliente" required
                      no-data-text="Nenhum dado disponível" :disabled="avaliacao.tipo_cliente == 'clientes' ? false : true
                        " data-vv-as="cliente" persistent-hint :hint="errors.first('id_cliente')" :error="errors.collect('id_cliente').length ? true : false
    " v-validate="avaliacao.tipo_cliente == 'clientes' ? 'required' : ''
    " data-vv-validate-on="change" id="id_cliente" name="id_cliente"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </Grid>
          <v-col cols="12">
            <v-textarea label="Introdução aos avaliadores" outlined rows="3" dense hide-details="auto"
              v-model="avaliacao.descricao"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Grid from "@/components/Grid.vue";
import store from "@/store";
import { onMounted, ref, computed, watch } from "@vue/composition-api";
import moment from "moment";
import { required, requiredNumeber } from "@core/utils/validation";
import { getLaterDate, setDayLaterDate } from "@/helpers";

export default {
  name: "avaliacao-Component",
  inject: ["$validator"],
  components: { Grid },
  setup(_, { emit }) {
    onMounted(() => {
      store.dispatch("usuarios/buscarGestores");
      store.dispatch("clientes/retornaClientes");
      store.dispatch("filiais/retornaFiliais");
      selectTabsUpdate();
    });
    // Data
    const tiposAvaliacoes = ref([]);
    const listaResponsaveis = ref([]);
    const radiosTypesClient = ref();
    const isStartDateMenuOpen = ref(false);
    const isEndDateMenuOpen = ref(false);
    const periodicidade = ref();
    const listaPeriodicidades = [
      { name: "Mensal" },
      { name: "Bimestral" },
      { name: "Trimestral" },
      { name: "Quadrimestral" },
      { name: "Semestral" },
      { name: "Anual" },
    ];
    const listaTiposAvaliacoes = [
      {
        sigla: "COM",
        name: "Comportamental",
        tipo: "comportamental",
        descricao: "Validar comportamentos dos colaboradores",
        icon: "mdi-account-arrow-up-outline",
      },
      {
        sigla: "TEC",
        name: "Técnica",
        tipo: "tecnica",
        descricao: "Validar técnicas dos colaboradores",
        icon: "mdi-account-arrow-right-outline",
      },
    ];
    const activeStep = ref("1");
    const avaliacao = computed(() => store.getters["avd/getAvaliacao"]);
    const listaGestores = computed(
      () => store.getters["usuarios/getResponsaveis"]
    );
    const listaClientes = computed(() => store.getters["clientes/getClientes"]);
    const listaFiliais = computed(() => store.getters["filiais/getFiliais"]);
    const loadingFiliais = computed(() => store.getters["filiais/getLoading"]);
    const tabsAvaliacaoForm = computed(() => store.getters["avd/getTabsForm"]);
    const tabFormSelected = computed(
      () => store.getters["avd/getTabFormSelected"]
    );
    const listaAvaliacaoPara = [
      { sigla: "propria", name: "Empresa" },
      { sigla: "clientes", name: "Clientes" },
    ];
    const validDateCurrent = () => {
      /**
       * NOTE
       * REGRA NEGOCIO: A data inicial deve ser sempre 2 dias aṕos a data atual
       * REGRA NEGOCIO: A data final deve ser sempre x dias aṕos a data inicial
       */
      return getLaterDate(2);
    };
    const validDateCurrentFinish = () => {
      /**
       * NOTE
       * REGRA NEGOCIO: A data inicial deve ser sempre 7 dias aṕos a data atual
       * REGRA NEGOCIO: A data final deve ser sempre x dias aṕos a data inicial
       */
      return setDayLaterDate(avaliacao.value.dataInicio, 7);
    };
    const iconSelectedTypeAvaliation = () => {
      if (listaTiposAvaliacoes && tiposAvaliacoes) {
        if (listaTiposAvaliacoes.length == tiposAvaliacoes.value.length)
          return "mdi-close-box";
        if (tiposAvaliacoes.value.length > 0) return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    };
    const toggleSelectTypeAvaliation = () => {
      listaTiposAvaliacoes.length == tiposAvaliacoes.value.length
        ? (tiposAvaliacoes.value = [])
        : (tiposAvaliacoes.value = listaTiposAvaliacoes.map((t) => t.sigla));
    };

    // Formata a data para o padrão do date picker
    const parseDate = (dates) => {
      if (!dates) return null;
      const [day, month, year] = dates.split("/");
      return `${year}-${month}-${day}`;
    };
    const dateInitialFormatted = computed(() => {
      return avaliacao.value.data_hora_inicio
        ? moment(avaliacao.value.data_hora_inicio).format("L")
        : "";
    });
    const dateFinishFormatted = computed(() => {
      return avaliacao.value.dataFinal
        ? moment(avaliacao.value.dataFinal).format("L")
        : "";
    });
    const selectTabsUpdate = () => {
      let tabsUpdate = [];
      if (
        avaliacao.value.comportamental &&
        avaliacao.value.comportamental == "A"
      ) {
        tabsUpdate.push(...["COM", "CONCOM"]);
      }
      if (avaliacao.value.tecnica && avaliacao.value.tecnica == "A") {
        tabsUpdate.push("TEC");
      }
      store.dispatch("avd/atualizarTabAvaliacaoForm", tabsUpdate);
    };
    const formataCampos = () => {
      avaliacao.value.id_filial = "";
      avaliacao.value.id_cliente = "";
    };

    async function save() {
      store.dispatch("snackbar/ativarSnackbarGlobal", {
        visibilidade: true,
        mensagem:
          "As datas das baterias das avaliações serão calculadas de acordo com a data da primeira avaliação, até a data final que será acresentados 12 meses a partir da data inicial.",
        cor: "info",
      });

      const isEdit = avaliacao.value.id != undefined ? 1 : -1;
      let url =
        isEdit === -1
          ? "/avd/avaliacoes"
          : `/avd/avaliacoes/${avaliacao.value.uuid}`;
      let method = isEdit === -1 ? "POST" : "PUT";
      let erro =
        isEdit === -1
          ? "Erro ao salvar avaliação."
          : "Erro ao atualizar avaliação.";
      avaliacao.value.dataFinal =
        avaliacao.value.periodicidade === "Mensal"
          ? avaliacao.value.dataFinal
          : "";
      let avaliacaoItem = avaliacao.value;
      avaliacaoItem._method = method;
      avaliacaoItem.url = url;
      avaliacaoItem.id_cliente =
        avaliacao.value.id_cliente != null ? avaliacao.value.id_cliente : null;
      avaliacaoItem.id_filial =
        avaliacao.value.id_filial != null ? avaliacao.value.id_filial : null;

      const result = await store
        .dispatch("avd/saveAvaliacao", avaliacaoItem)

      if (result) {
        store.dispatch("avd/setAvaliacao", result);
        store.dispatch("avdTecnicas/calculaDadosDistribuicaoDeValores");

        if (isEdit === 1) {
          // avaliacao.value.id = response.id;
          // avaliacao.value.uuid = response.uuid;
        }
      } else {
        store.dispatch("snackBarModule/openSnackBar", {
          color: "danger",
          timeout: 3000,
          text: erro,
        });
      }

    }

    async function validate() {
      return this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (
            avaliacao.value.comportamental == "A" ||
            avaliacao.value.tecnica == "A"
          ) {
            return await save();
          }

          store.dispatch("snackBarModule/openSnackBar", {
            color: "danger",
            timeout: 3000,
            text: "Obrigatório selecionar pelo menos um tipo de avaliação.",
          });

          return false;
        } else {
          store.dispatch("snackBarModule/openSnackBar", {
            color: "danger",
            timeout: 3000,
            text: "Preencha todo formulário antes de prosseguir",
          });

          return false;
        }
      });
    }

    return {
      // Variables
      avaliacao,
      listaResponsaveis,
      listaClientes,
      listaGestores,
      listaFiliais,
      loadingFiliais,
      listaTiposAvaliacoes,
      listaAvaliacaoPara,
      radiosTypesClient,
      listaPeriodicidades,
      periodicidade,
      tiposAvaliacoes,
      dateInitialFormatted,
      dateFinishFormatted,

      // Variables config
      isStartDateMenuOpen,
      isEndDateMenuOpen,
      activeStep,
      validators: {
        required,
        requiredNumeber,
      },

      // Functions
      validDateCurrent,
      validDateCurrentFinish,
      iconSelectedTypeAvaliation,
      toggleSelectTypeAvaliation,
      parseDate,
      selectTabsUpdate,
      save,
      formataCampos,
      validate,

      // Computed
      tabsAvaliacaoForm,
      tabFormSelected,
    };
  },
};
</script>

<style lang="scss"></style>
